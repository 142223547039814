<div
	class="softphone"
	id="draggBox"
	:style="{width: phoneLines.currentContainerSize + 'px'}"
>
	<form id="formagentemain" name="formagentemain" class="softphoneBack">
		<div class="row justify-content-end">
			<!-- Multilineas -->

			<div :class="phoneLines.colLinesClass">
				<div v-show="lineas_activas_llamadas">
					<div
						class="container text-center bg-white pt-2 overflow-auto"
						id="webLines"
					>
						<div class="m-0 p-0">
							<div class="accordion mr--2 ml-2" id="accordionDada">
								<div class="card mb-1" v-for="item in datos_lineas_activas">
									<div class="card-header p-1" :id="item.title">
										<h2 class="mb-0">
											<button
												class="btn btn-block text-left collapsaded"
												type="button"
												data-toggle="collapse"
												:data-target="(conferenciando_multi_linea || transfiriendo_multi_linea ) ? 'func':'#collapse-'+item.title"
												aria-expanded="false"
												:aria-controls="'collapse-'+item.title"
												@click.capture="()=>{changeLines(item.lineas_llamadas_activas)}"
											>
												<i
													:class="item.action"
													class="list-inline-item ml-4"
												></i>
												<div class="list-inline-item">{{item.title}}</div>
												<div class="list-inline-item">
													{{item.llamada_tiempo}}
												</div>
											</button>
										</h2>
									</div>
									<div
										:id="'collapse-'+item.title"
										class="collapse"
										:aria-labelledby="item.title"
										data-parent="#accordionDada"
									>
										<div class="card-body">
											<div v-for="child in item.items" :key="child.la_linea">
												<div class="d-flex justify-content-center">
													<!-- Contestar -->
													<soft-phone-button
														v-show="lineas_activas"
														:key="child.la_linea"
														@click.capture="()=>{AnswerAudioCall(child.la_linea)}"
														icon="phone"
														type="success"
														size="35"
														class="mx-2"
													></soft-phone-button>
													<!-- Rechazar -->
													<soft-phone-button
														v-show="lineas_activas"
														:key="child.la_linea"
														@click.capture="()=>{RejectCall(child.la_linea);}"
														icon="phone-hangup"
														type="danger"
														size="35"
														class="mx-2"
													></soft-phone-button>
													<!-- Finalizar -->
													<soft-phone-button
														v-show="finalizar_linea_activas"
														:key="child.la_linea"
														@click.capture="()=>{endSession(child.la_linea);}"
														icon="phone-hangup"
														type="danger"
														size="35"
														class="mx-2"
													></soft-phone-button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- WebPhone -->
			</div>

			<div :class="phoneLines.colPhoneClass">
				<div id="webPhone" :class="phoneLines.colBodyPhoneClass">
					<div style="display: none">
						<canvas id="cdr-AudioReceiveBitRate"></canvas>
					</div>
					<div style="display: none">
						<canvas id="cdr-AudioReceivePacketRate"></canvas>
					</div>
					<div style="display: none">
						<canvas id="cdr-AudioReceivePacketLoss"></canvas>
					</div>
					<div style="display: none">
						<canvas id="cdr-AudioReceiveJitter"></canvas>
					</div>
					<div style="display: none">
						<canvas id="cdr-AudioReceiveLevels"></canvas>
					</div>
					<button
						id="btn-call-oneline"
						@click="()=>{DialByLine('audio');}"
						type="button"
						hidden
					></button>
					<div class="closeHead d-flex justify-content-between" id="draggHead">
						<h5 class="my-auto ml-1">WebPhone</h5>
						<div>
							<div class="d-flex flex-row px-2">
								<div class="angle mr-3" @click.capture="pin()">
									<i class="fa fa-thumbtack fa-xs"></i>
								</div>
								<div class="angle" @click="model.show = !model.show">
									<i class="fa fa-angle-down fa-xs" v-show="model.show"></i>
									<i class="fas fa-angle-up fa-xs" v-show="!model.show"></i>
								</div>
							</div>
						</div>
					</div>
					<el-button id="closeSession" v-show="false"></el-button>
					<el-collapse-transition v-if="true">
						<transition name="el-zoom-in-bottom">
							<div v-show="model.show" class="transition-box">
								<div class="top">
									<div class="status-bars left">
										<div
											class="bar"
											v-for="a in model.barCount"
											:class="{ active: model.barCount - a < model.barLevel }"
											:key="a"
										>
											<div></div>
										</div>
									</div>

									<Avatar picture="/img/icons/dms2.jpg" />

									<div class="status-bars right">
										<div
											class="bar"
											v-for="a in model.barCount"
											:class="{ active: a <= model.barLevel }"
											:key="a"
										></div>
									</div>
								</div>
							</div>
						</transition>
					</el-collapse-transition>
					<div class="middle">
						<!-- <h6>Datos de llamadas:{{datos_llamada}}</h6>
						<h6>Tiempo de llamadas:{{tiempo_llamada}}</h6> -->

						<div v-show="lienea_uno_activa || true">
							<span class="duration" v-show="datos_llamada ">
								<!-- {{tiempo_llamada}} -->
								{{datos_lineas_activas[numero_linea]?.llamada_tiempo}}
							</span>
							<!-- <base-checkbox
								name="checkBoxCall"
								v-show=" (respuesta_automatica==true && model.show==true) "
								v-model="contestar_automatico"
								:inline="true"
							>
								Respuesta automática
							</base-checkbox>  -->
							<div class="d-flex justify-content-center">
								<el-checkbox 
								name="checkBoxCall"
								class="mb--2" 
								v-show="(respuesta_automatica==true && model.show==true)" 
								v-model="contestar_automatico" 
								label="Respuesta automática" 
								size="large"
								:border="false"
								:disabled="force_auto_answer"

								 />
							</div>

			

							<el-tooltip
							class="box-item"
							:effect="tooltip_effect"
							:placement="tooltip_placement"
							:show-after="tooltip_show_after "
							:v-show="nombre_largo.length !== 0 "
						>
							<template #content>{{nombre_largo}}<br/> Telefono: {{numero_hablando}} </template>
							<div>


									<div v-if="nombre_corto.length !== 0 ">									
									<h3 v-if="nombre_corto.length < 25 " class="duration font-weight-normal my-0">
										
										<i>
											{{nombre_corto}}
										</i>
									</h3> 
									<div v-if="nombre_corto.length >= 25 " class="marquee-parent">
										<div class="marquee-container" style="max-width: 240px;">
											<h3  class="d-inline-block marquee-text">
												<i class="">{{nombre_corto}}</i>
																						
											</h3>
										</div> 
									</div>
									<h4 class="duration font-weight-normal  mt-0" >
										<a @click="copyText(numero_hablando)">
											{{numero_hablando}} 
										</a>
									</h4>
								</div>
								<h3 v-else class="duration font-weight-normal  mt-0">
									<a @click="copyText(numero_hablando)">
										{{numero_hablando}} 
									</a>
	
								</h3>
	
							</div>


							
						</el-tooltip>
							<div
								class="col-12 text-center my-0 py-0 mt-2"
								v-if="transfiriendo_multi_linea || conferenciando_multi_linea"
							>
								<h4
									class="duration font-weight-bold text-warning"
									v-if="conectando_conferencia"
								>
									Conectando
									<div style="font-size: 0.5rem">
										<i class="fa fa-circle fa-1x mt-1 ml-2"></i>
									</div>
								</h4>
								<h4
									class="duration font-weight-bold text-success"
									v-if="en_conferencia"
								>
									En conferencia
									<div style="font-size: 0.5rem">
										<i class="fa fa-circle fa-1x mt-1 ml-2"></i>
									</div>
								</h4>
								<h4 class="duration font-weight-normal">{{numero_hablando}}</h4>
								<h4 class="duration font-weight-normal">
									<i
										v-if="transfiriendo_multi_linea"
										class="fas fa-exchange-alt fa-lg"
									></i>
									<i
										v-if="conferenciando_multi_linea"
										class="fas fa-users fa-lg"
									></i>
								</h4>
								<h4
									class="duration font-weight-normal"
									v-if="transfiriendo_multi_linea"
								>
									{{datos_lineas_activas[linea_a_transferir]?.title}}
								</h4>
								<h4
									class="duration font-weight-normal"
									v-if="conferenciando_multi_linea"
								>
									{{numero_conferenciar}}
								</h4>

								<el-button
									v-if="transfiriendo_multi_linea"
									type="success"
									class="font-weight-bold"
									color="#7bcc91"
									v-show="linea_a_transferir>0"
									@click.capture="BlindTransferMultiline({numero_linea})"
									plain
									>Transferir</el-button
								>
								<el-button
									v-if="conferenciando_multi_linea"
									type="warning"
									class="font-weight-bold"
									color="#EFB541"
									v-show="linea_a_conferenciar>0 && !en_conferencia"
									@click.capture="ConferenceMultiLine2(numero_linea)"
									plain
									>Conferencia</el-button
								>
							</div>
							<div style="display: flex; justify-content: center;">
								<input
									v-show="digitar_telefono"
									v-model="numero_telefonico"
									class="number mt-2"
									@keypress="isNumber($event)"
									@paste="pasteHandle($event,'numero_telefonico')"
									type="tel"
									pattern="[0-9]{4}-[0-9]{4}"
									id="campo-telefono"
									autocomplete="off"
									@keyup.enter="FilterDialByLine()"
								/>
								<input
									v-show="digitar_transferencia"
									v-model="telefono_tranferencia"
									class="number mt-2"
									@keypress="isNumber($event)"
									@paste="pasteHandle($event,'telefono_tranferencia')"
									type="text"
									id="campo-transferencia"
									autocomplete="off"
									@keyup.enter="BlindTransfer({numero_linea})"
								/>
								<input
									v-show="digitar_conferencia"
									v-model="telefono_conferencia"
									class="number mt-2"
									@keypress="isNumber($event)"
									@paste="pasteHandle($event,'telefono_conferencia')"
									type="text"
									id="campo-conferencia"
									autocomplete="off"
									@keyup.enter="ConferenceDail({numero_linea})"
								/>
								<input
									v-show="digitar_nueva_linea"
									v-model="telefono_nueva_linea"
									@paste="pasteHandle($event,'telefono_nueva_linea')"
									class="number mt-2"
									@keypress="isNumber($event)"
									type="text"
									id="campo-nueva-linea"
									autocomplete="off"
									@keyup.enter="DialMultiLine({numero_linea})"
								/>
							</div>
						</div>
					</div>
					<el-collapse-transition>
						<transition name="el-zoom-in-bottom">
							<div v-show="model.show" class="transition-box">
								<div class="middlePad">
									<div>
										<!-- Opciones de transferencia -->
										<div
											class="d-flex justify-content-center"
											v-if="digitar_transferencia && lienea_uno_activa"
										>
											<el-button
												type="primary"
												v-show="opciones_transferencia"
												@click.capture="BlindTransfer({numero_linea})"
												text
												>Directa</el-button
											>
											<el-button
												type="primary"
												v-show="opciones_transferencia"
												@click.capture="AttendedTransfer({numero_linea})"
												text
												>Atendida</el-button
											>
											<el-button
												type="primary"
												id="cancelar_transferencia"
												v-show="tranferencia_cancelada"
												text
												>Cancelar</el-button
											>
											<el-button
												type="primary"
												id="realizar_transferencia"
												v-show="tranferencia_atendida"
												@click.capture="this.stopDuration()"
												text
												>Transferir</el-button
											>
											<el-button
												type="primary"
												id="colgar_transferencia"
												v-show="tranferencia_atendida"
												text
												>Finalizar</el-button
											>
										</div>
										<!-- Fin opciones de transferencia -->
										<!-- Opciones de Conferencia -->
										<div
											class="d-flex justify-content-center"
											v-if="digitar_conferencia && lienea_uno_activa"
										>
											<el-button
												type="primary"
												v-show="opciones_conferencia==true"
												@click.capture="ConferenceDail({numero_linea})"
												text
												>Llamar</el-button
											>
											<el-button
												type="primary"
												v-show="cancelar_conferencia"
												id="conferencia_cancelar"
												text
												>Cancelar</el-button
											>
											<el-button
												type="primary"
												id="realizar_conferencia"
												v-show="linea_conferencia"
												text
												>Realizar</el-button
											>
											<el-button
												type="primary"
												id="conferencia_finalizar"
												v-show="linea_conferencia"
												text
												>Finalizar</el-button
											>
										</div>
										<!-- Fin opciones de Conferencia -->
										<!-- Opciones de nueva linea -->
										<div
											class="d-flex justify-content-center"
											v-if="digitar_nueva_linea"
										>
											<el-button
												type="primary"
												v-show="opciones_nueva_linea"
												@click.capture="DialMultiLine({numero_linea})"
												text
												>Llamar</el-button
											>

											<el-button
												type="primary"
												id="realizar_transferencia"
												v-show="nueva_linea_atendida"
												@click.capture="this.stopDuration()"
												text
												>Transferir</el-button
											>
											<el-button
												type="primary"
												id="colgar_transferencia"
												v-show="tranferencia_atendida"
												text
												>Finalizar</el-button
											>
										</div>
										<!-- Fin opciones de Conferencia -->
										<!-- PAD NUMERICO -->
										<div>
											<div class="numericPad mt-3">
												<soft-phone-button
													icon="1"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'1')"
												>
												</soft-phone-button>
												<soft-phone-button
													icon="2"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'2')"
												>
												</soft-phone-button>
												<soft-phone-button
													icon="3"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'3')"
												>
												</soft-phone-button>
											</div>
											<div class="numericPad">
												<soft-phone-button
													icon="4"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'4')"
												>
												</soft-phone-button>
												<soft-phone-button
													icon="5"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'5')"
												>
												</soft-phone-button>
												<soft-phone-button
													icon="6"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'6')"
												>
												</soft-phone-button>
											</div>
											<div class="numericPad">
												<soft-phone-button
													icon="7"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'7')"
												>
												</soft-phone-button>
												<soft-phone-button
													icon="8"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'8')"
												>
												</soft-phone-button>
												<soft-phone-button
													icon="9"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'9')"
												>
												</soft-phone-button>
											</div>
											<div class="numericPad">
												<soft-phone-button
													icon="*"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'*')"
												>
												</soft-phone-button>
												<soft-phone-button
													icon="0"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'0')"
												>
												</soft-phone-button>
												<soft-phone-button
													icon="#"
													:isNumber="true"
													@click-event="concatNumbers({numero_linea},'#')"
												>
												</soft-phone-button>
											</div>
										</div>
										<!-- FIN PAD NUMERICO -->
									</div>
								</div>
							</div>
						</transition>
					</el-collapse-transition>
					<div class="bottom" v-show="lienea_uno_activa || true">
						<!-- Llamada saliente -->
						<div class="actions">
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Llamar"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<soft-phone-button
									v-show="llamadaSaliente"
									@click="()=>{DialByLine('audio');}"
									icon="phone-out"
									type="success"
								></soft-phone-button>
							</el-tooltip>

							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Cancelar llamada saliente"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<soft-phone-button
									v-show="cancelar_llamada_saliente"
									@click.capture="()=>{cancelSession({numero_linea});}"
									icon="phone-hangup"
									type="danger"
								></soft-phone-button>
							</el-tooltip>
						</div>
						<!-- Fin Llamada saliente -->
						<!-- Opciones de llamada -->
						<div class="actions" v-show="model.show">
							<!-- Mutear -->
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Mutear"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<soft-phone-button
									v-show="activarmute"
									@click-event="()=>{MuteSession({numero_linea});this.stopDuration()}"
									icon="mute"
									type="primary"
								></soft-phone-button>
							</el-tooltip>
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Reconectar audio"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<soft-phone-button
									v-show="desactivarmute"
									@click-event="()=>{UnmuteSession({numero_linea});this.startDuration()}"
									icon="unmute"
									type="warning"
								></soft-phone-button>
							</el-tooltip>
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Poner en espera"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<!-- Espera -->
								<soft-phone-button
									v-show="poner_en_espera"
									@click-event="validateConference() && holdSession({numero_linea})"
									icon="phone-pause"
									type="primary"
								></soft-phone-button>
							</el-tooltip>
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Reconectar"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<soft-phone-button
									v-show="quitar_en_espera || false"
									@click-event="unholdSession({numero_linea})"
									icon="phone-return"
									type="warning"
								></soft-phone-button>
							</el-tooltip>
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Transferir"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<!-- Transferencia -->
								<soft-phone-button
									v-show="transferencia"
									@click-event="validateConference() && StartTransferSession({numero_linea})"
									icon="share"
									type="primary"
									:disabled="callIsRinging"
								></soft-phone-button>
							</el-tooltip>
						
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Cancelar transferencia"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<soft-phone-button
									v-show="cancelar_transferencia"
									@click.capture="CancelTransferSession({numero_linea})"
									icon="shareoff"
									type="warning"
								></soft-phone-button>
							</el-tooltip>
						</div>
						<div class="actions">
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Empezar conferencia"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<!-- Conferencia -->
								<soft-phone-button
									v-show="conferencia && model.show"
									@click-event="StartConferenceCall({numero_linea})"
									icon="group"
									type="primary"
									:disabled="callIsRinging"

								></soft-phone-button>
							</el-tooltip>
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Cancelar conferencia"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<soft-phone-button
									v-show="desactivar_conferencia && model.show"
									@click.capture="CancelConference({numero_linea})"
									icon="group"
									type="warning"
								></soft-phone-button>
							</el-tooltip>
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Nueva linea"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<!-- Llamar nueva linea -->
								<soft-phone-button
									v-show="nueva_linea && model.show"
									@click.capture="validateConference() && StartNewLineSession({numero_linea})"
									icon="phone-plus"
									type="success"
									:disabled="callIsRinging"
								></soft-phone-button>
							</el-tooltip>
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Cancelar nueva linea"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<soft-phone-button
									v-show="cancelar_nueva_linea && model.show"
									@click.capture="CancelNewLine({numero_linea})"
									icon="phone-remove"
									type="warning"
								></soft-phone-button>
							</el-tooltip>
							<!-- Fin nueva linea -->
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Finalizar llamada"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<soft-phone-button
									v-show="finalizar_llamada"
									@click.capture="()=>{endSession({numero_linea});}"
									icon="phone-hangup"
									type="danger"
									name="Finalizar llamada"
								></soft-phone-button>
							</el-tooltip>
						</div>
						<!-- Fin opciones llamada -->
						<!-- Llamada Entrante -->
						<div class="actions" v-show="lienea_uno_activa">
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="Responder llamada"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<soft-phone-button
									v-show="contestar"
									@click.capture="()=>{AnswerAudioCall({numero_linea});model.show=false;}"
									icon="phone-in"
									type="success"
								></soft-phone-button>
							</el-tooltip>
							<el-tooltip
								class="box-item"
								:effect="tooltip_effect"
								content="colgar"
								:placement="tooltip_placement"
								:show-after="tooltip_show_after"
							>
								<soft-phone-button
									v-show="colgar"
									@click.capture="()=>{RejectCall({numero_linea});}"
									icon="phone-hangup"
									type="danger"
									content="colgar"
								></soft-phone-button>
							</el-tooltip>
						</div>
						<!-- Fin Llamada entrante -->
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
